import { Nav } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from 'providers/AppProvider';
import apiService from '../../../services/apiService';
import { UilDownloadAlt } from '@iconscout/react-unicons';

const NavItems = () => {
  const fileUrl =
    'https://essfilesuat.blob.core.windows.net/media-files/app.apk';
  const fileName = 'StarQuest.apk';
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setPlayer, clearPlayer } = useAppContext();

  useEffect(() => {
    getPlayer();
  }, [pathname]);

  const getPlayer = async () => {
    try {
      const response = await apiService.getAgent();
      setPlayer(response.data);
    } catch {
      console.log("You're not logged in");
      clearPlayer();
      navigate('/sign-in', { replace: true });
    }
  };

  const handleLogout = () => {
    apiService.logout();
    clearPlayer();
    navigate('/sign-in', { replace: true });
  };

  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      <Nav.Item>
        <a href={fileUrl} download={fileName}>
          <UilDownloadAlt size={28} className="me-4 text-900" />
        </a>
      </Nav.Item>
      <Nav.Item onClick={handleLogout}>
        <FeatherIcon icon="log-out" size={23} className="text-900" />
      </Nav.Item>
    </div>
  );
};

export default NavItems;
