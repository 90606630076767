import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import SignUp from 'pages/authentication/SignUp';
import Recruitments from './Recruitments';
import Transactions from './Transactions';

function Home() {
  return (
    <Row className="flex-center position-relative min-vh-98 g-0 p-lg-5 p-sm-0">
      <Col xs={12} sm={10} xl={8}>
        <Card className="border border-200 auth-card">
          <Card.Body>
            <Tab.Container defaultActiveKey="agents">
              <Nav variant="underline" className="mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="agents">Agents</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="register">Register</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="payments">Payments</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="agents">
                  <Recruitments />
                </Tab.Pane>
                <Tab.Pane eventKey="register">
                  <SignUp />
                </Tab.Pane>
                <Tab.Pane eventKey="payments">
                  <Row className="g-3">
                    <Transactions />
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default Home;
