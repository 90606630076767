import { useState, useEffect } from 'react';
import apiService, { RecruitmentInfo } from '../../services/apiService';
import { Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import DatePicker from 'components/base/DatePicker';
import Button from 'components/base/Button';

function Recruitments() {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [recruitments, setRecruitments] = useState<RecruitmentInfo[]>([]);

  useEffect(() => {
    const fetchRecruitments = async () => {
      await getRecruitments();
    };

    fetchRecruitments();
  }, [date]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setErrorMessage('');
    }
  }, [errorMessage]);

  const getRecruitments = async () => {
    try {
      setLoading(true);
      const response = await apiService.getRecruitments(date);
      setRecruitments(response.data);
    } catch (error) {
      const axiosError = error as AxiosError;
      setErrorMessage(
        (axiosError?.response?.data as string) || 'Error getting data'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row className="flex-center position-relative min-vh-98 g-0 p-lg-5 p-sm-0">
      <Col xs={12} sm={10} xl={8}>
        <Row className="g-3">
          <div
            className={classNames(
              'position-relative px-lg-7 py-7 pb-sm-5 text-center text-md-center pb-lg-7'
            )}
          >
            <Row className="g-2">
              <Col xs={12} md={8}>
                <DatePicker
                  placeholder="Select a date"
                  onChange={(_dateAry, dateString) => setDate(dateString)}
                  options={{ dateFormat: 'Y-m-d' }}
                />
              </Col>
              <Col xs={12} md={4} className="mt-2">
                <Button variant="secondary" onClick={getRecruitments}>
                  Refresh
                </Button>
              </Col>
            </Row>
            <Spinner
              animation="border"
              role="status"
              className={classNames({
                hide: !loading
              })}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>

            <div
              className={classNames({
                hide: loading
              })}
            >
              <Row className="mt-5 fw-bold">
                <Col xs={8} md={8} lg={8}>
                  Name
                </Col>
                <Col xs={4} md={4} lg={4}>
                  Sales (₦)
                </Col>
              </Row>
              {recruitments.map((recruitment, index) => {
                return (
                  <Row key={index} className={classNames('mt-5', {})}>
                    <Col xs={8} md={8} lg={8}>
                      {recruitment.firstName} {recruitment.lastName}
                    </Col>
                    <Col xs={4} md={4} lg={4}>
                      {recruitment.salesInNaira}
                    </Col>
                  </Row>
                );
              })}
            </div>
          </div>
        </Row>
      </Col>
    </Row>
  );
}

export default Recruitments;
