import Button from 'components/base/Button';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import apiService, { Bank } from 'services/apiService';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';
import CustomMenu from 'components/base/CustomMenu';
import CustomToggle from 'components/base/CustomToggle';

const SignUp = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [bank, setBank] = useState<Bank>({ code: '', name: 'Select a Bank' });
  const [banks, setBanks] = useState<Bank[]>([]);
  const [firstSecurityQuestion, setFirstSecurityQuestion] = useState('');
  const [firstSecurityAnswer, setFirstSecurityAnswer] = useState('');
  const [secondSecurityQuestion, setSecondSecurityQuestion] = useState('');
  const [secondSecurityAnswer, setSecondSecurityAnswer] = useState('');

  const securityQuestionList = [
    'In what city were you born?',
    'What is the name of your favorite pet?',
    "What is your mother's maiden name?",
    'What high school did you attend?',
    'What is the name of your first school?',
    'What was the make of your first car?',
    'What was your favorite food as a child?',
    'Where did you meet your spouse?'
  ];

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setErrorMessage('');
    }
    if (successMessage) {
      toast.success(successMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setSuccessMessage('');
    }
  }, [errorMessage, successMessage]);

  useEffect(() => {
    const fetchBanks = async () => {
      await getBanks();
    };

    fetchBanks();
  }, []);

  const getBanks = async () => {
    try {
      const response = await apiService.getBanks();
      setBanks(response.data);
    } catch (error) {
      const axiosError = error as AxiosError;
      setErrorMessage(
        (axiosError?.response?.data as string) || 'Error getting banks'
      );
    }
  };

  const handleSignUp = async () => {
    if (
      !username ||
      !password ||
      !confirmPassword ||
      !firstName ||
      !lastName ||
      !phoneNumber ||
      !accountNumber ||
      !firstSecurityQuestion ||
      !firstSecurityAnswer ||
      !secondSecurityQuestion ||
      !secondSecurityAnswer ||
      !bank.code ||
      !email
    ) {
      setErrorMessage('All fields are required');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    if (bank.code === '') {
      setErrorMessage('Please select a bank');
      return;
    }

    if (phoneNumber.length !== 11) {
      setErrorMessage('Phone number must be 11 digits');
      return;
    }

    if (accountNumber.length < 10) {
      setErrorMessage('Account number must be at least 10 digits');
      return;
    }

    if (firstSecurityQuestion === secondSecurityQuestion) {
      setErrorMessage('Security questions cannot be the same');
      return;
    }

    if (firstSecurityAnswer === secondSecurityAnswer) {
      setErrorMessage('Security answers cannot be the same');
      return;
    }

    const agent = {
      username,
      password,
      firstName,
      lastName,
      phoneNumber,
      accountNumber,
      email,
      bankCode: bank.code,
      firstSecurityQuestion,
      firstSecurityQuestionAnswer: firstSecurityAnswer,
      secondSecurityQuestion,
      secondSecurityQuestionAnswer: secondSecurityAnswer
    };

    try {
      setLoading(true);
      await apiService.register(agent);
      setSuccessMessage('Agent registered successfully');

      // clear form
      setUsername('');
      setPassword('');
      setConfirmPassword('');
      setFirstName('');
      setLastName('');
      setPhoneNumber('');
      setAccountNumber('');
      setBank({ code: '', name: 'Select a Bank' });
      setFirstSecurityQuestion('');
      setFirstSecurityAnswer('');
      setSecondSecurityQuestion('');
      setSecondSecurityAnswer('');
    } catch (error) {
      const axiosError = error as AxiosError;
      setErrorMessage(
        (axiosError?.response?.data as string) || 'Error registering agent'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form noValidate>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="firstName">First Name</Form.Label>
          <Form.Control
            id="firstName"
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="lastName">Last Name</Form.Label>
          <Form.Control
            id="lastName"
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="username">Username</Form.Label>
          <Form.Control
            id="username"
            type="text"
            placeholder="Username"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </Form.Group>

        <Row className="g-3 mb-3">
          <Col sm={12} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="password">Password</Form.Label>
              <Form.Control
                id="password"
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col sm={12} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="confirmPassword">
                Confirm Password
              </Form.Label>
              <Form.Control
                id="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Email</Form.Label>
          <Form.Control
            id="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="text">Phone Number</Form.Label>
          <Form.Control
            id="phoneNumber"
            type="text"
            placeholder="08012345678"
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="accountNumber">Account Number</Form.Label>
          <Form.Control
            id="accountNumber"
            type="string"
            placeholder="0123456789"
            value={accountNumber}
            onChange={e => setAccountNumber(e.target.value)}
          />
        </Form.Group>
        <Col sm={12} lg={12}>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              <Form.Group className="mb-3 text-start">
                <Form.Label htmlFor="accountNumber">Bank</Form.Label>
                <Form.Control
                  aria-label="Select a Bank"
                  value={bank.name}
                  readOnly
                />
              </Form.Group>
            </Dropdown.Toggle>

            <Dropdown.Menu as={CustomMenu}>
              {banks.map(bank => (
                <Dropdown.Item
                  eventKey={bank.code}
                  key={bank.code}
                  onClick={() => setBank(bank)}
                >
                  {bank.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>

        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="firstSecurityQuestion">
            Fist Security Question
          </Form.Label>
          <Form.Select
            id="firstSecurityQuestion"
            aria-label="Select a Question"
            onChange={e => setFirstSecurityQuestion(e.target.value)}
          >
            <option>Select a security question</option>
            {securityQuestionList.map(question => (
              <option key={question} value={question}>
                {question}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="firstSecurityAnswer">First Answer</Form.Label>
          <Form.Control
            id="firstSecurityAnswer"
            type="text"
            placeholder="Answer"
            value={firstSecurityAnswer}
            onChange={e => setFirstSecurityAnswer(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="secondSecurityQuestion">
            Second Security Question
          </Form.Label>
          <Form.Select
            id="secondSecurityQuestion"
            aria-label="Select a Question"
            onChange={e => setSecondSecurityQuestion(e.target.value)}
          >
            <option>Select a security question</option>
            {securityQuestionList.map(question => (
              <option key={question} value={question}>
                {question}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="secondSecurityAnswer">Second Answer</Form.Label>
          <Form.Control
            id="secondSecurityAnswer"
            type="text"
            placeholder="Answer"
            value={secondSecurityAnswer}
            onChange={e => setSecondSecurityAnswer(e.target.value)}
          />
        </Form.Group>

        <Button
          variant="secondary"
          onClick={handleSignUp}
          disabled={loading}
          className="w-100 mb-3"
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Sign Up'
          )}
        </Button>
      </Form>
    </>
  );
};

export default SignUp;
