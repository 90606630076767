import AuthCardLayout from 'layouts/AuthCardLayout';
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Form, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import apiService from '../../services/apiService';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

const SignIn = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      setErrorMessage('');
    }
  }, [errorMessage]);

  const handleSignIn = async () => {
    try {
      setDisableButton(true);
      const token = (await apiService.login(username, password)).data.token;
      await apiService.setAuthToken(token);
      navigate('/home');
    } catch (error) {
      const axiosError = error as AxiosError;
      setErrorMessage(
        (axiosError?.response?.data as string) || 'Error signing in'
      );
    } finally {
      setDisableButton(false);
    }
  };

  return (
    <AuthCardLayout className="pb-md-7">
      <div className="text-center mb-7">
        <h3 className="text-1000">Sign In</h3>
      </div>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="email">Username</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="username"
            type="username"
            className="form-icon-input"
            placeholder="Your username"
            onChange={e => setUsername(e.target.value)}
          />
          <FontAwesomeIcon icon={faUser} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="password">Password</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="password"
            type="password"
            className="form-icon-input"
            placeholder="Password"
            onChange={e => setPassword(e.target.value)}
          />
          <FontAwesomeIcon icon={faKey} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Button
        variant="secondary"
        onClick={handleSignIn}
        disabled={disableButton}
        className="w-100 mb-3"
      >
        {disableButton ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          'Sign In'
        )}
      </Button>
    </AuthCardLayout>
  );
};

export default SignIn;
