import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter, Navigate } from 'react-router-dom';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import SignIn from 'pages/authentication/SignIn';
import App from 'App';
import Home from 'pages/app/Home';
import Error404 from 'pages/error/Error404';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: <Navigate to="/home" />
      },
      {
        path: '/sign-in',
        element: <SignIn />
      },
      {
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            path: '/home',
            element: <Home />
          }
        ]
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
