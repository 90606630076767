import { Icon } from '@iconscout/react-unicons';

export interface Route {
  label: string;
  icon: Icon;
  labelDisabled?: boolean;
  megaMenu?: boolean;
  active?: boolean;
  path: string;
}

export const routes: Route[] = [];
